<template>
  <b10-base>
    <b10-textarea
      v-model="form.comentario_final_tecnico"
      label="Comentario final del técnico"
    />
    <v-checkbox
      v-model="form.manodeobrafacturable"
      label="Mano de obra facturable"
      :disabled="!hasPermModificarManoObraFacturable"
    />
    <b10-autocomplete
      v-model="form.idtfacturacion_kilometros"
      :rules="formRules.idtfacturacion_kilometros"
      :items="tfacturacion"
      item-value="id"
      item-text="descripcion"
      label="Desplazamiento"
      clearable
      :disabled="!hasPermModificarDesplazamientoFacturable"
    />
    <b10-autocomplete
      v-model="form.idalmacen"
      :rules="formRules.idalmacen"
      :items="almacen"
      item-value="almacen.idalmacen"
      item-text="almacen.descripcion"
      label="Almacén"
      clearable
    />
    <b10-autocomplete
      v-model="form.idtmotivo_bloqueo_ot"
      :items="tmotivoBloqueoOt"
      item-value="tmotivo_bloqueo_ot.idtmotivo_bloqueo_ot"
      item-text="tmotivo_bloqueo_ot.descripcion"
      label="Motivo de bloqueo"
      clearable
    />
    <b10-date-time-picker
      v-model="form.ffijada"
      title="Fecha fijada"
      :disabled="!hasPermModificarFechasPlanificacion"
    />
    <b10-date-time-picker
      v-model="form.fprevista_fin"
      title="Fecha prevista de fin"
      :disabled="!hasPermModificarFechasPlanificacion"
    />
    <b10-textarea
      v-model="form.notas_internas"
      label="Notas internas"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ParteFormData'
import { TFACTURACION_KILOMETROS } from '@/utils/consts'

export default {
  mixins: [formMixin],
  props: {
    hasPermModificarManoObraFacturable: {
      type: Boolean,
      default: false,
    },
    hasPermModificarDesplazamientoFacturable: {
      type: Boolean,
      default: false,
    },
    hasPermModificarFechasPlanificacion: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        comentario_final_cliente: null,
        comentario_final_tecnico: null,
        manodeobrafacturable: true,
        idtfacturacion_kilometros: null,
        idalmacen: null,
        idtmotivo_bloqueo_ot: null,
        ffijada: null,
        fprevista_fin: null,
        notas_internas: null,
      },
      formRules: {
        idtfacturacion_kilometros: [
          v => !!v || 'Campo requerido'
        ],
        idalmacen: [
          v => !!v || 'Campo requerido'
        ],
      },
      almacen: [],
      tfacturacion: [],
      tmotivoBloqueoOt: []
    }
  },
  async created () {
    this.almacen = await Data.selectAlmacen(this)
    this.tfacturacion = TFACTURACION_KILOMETROS.tipos
    this.tmotivoBloqueoOt = await Data.selectTmotivoBloqueoOt(this)
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.$set(this.form, 'comentario_final_cliente', formData.parte_trabajo.comentario_final_cliente)
      this.$set(this.form, 'comentario_final_tecnico', formData.parte_trabajo.comentario_final_tecnico)
      this.$set(this.form, 'manodeobrafacturable', formData.parte_trabajo.manodeobrafacturable)
      this.$set(this.form, 'idtfacturacion_kilometros', formData.parte_trabajo.idtfacturacion_kilometros)
      this.$set(this.form, 'idalmacen', formData.parte_trabajo.idalmacen)
      this.$set(this.form, 'idtmotivo_bloqueo_ot', formData.orden_trabajo.idtmotivo_bloqueo_ot)
      this.$set(this.form, 'ffijada', formData.parte_trabajo.ffijada)
      this.$set(this.form, 'fprevista_fin', formData.parte_trabajo.fprevista_fin)
      this.$set(this.form, 'notas_internas', formData.orden_trabajo.notas_internas)
    },
  }
}
</script>
