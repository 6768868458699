export default {
  selectAlmacen (Vue) {
    const tables = Vue.$offline.db.tables
    const almacenResource = Vue.$offline.almacen
    return Vue.$offline.db
      .select()
      .from(tables.almacen)
      .innerJoin(almacenResource.dummyTable, almacenResource.pk.eq(almacenResource.dummyPk))
      .where(tables.almacen.estado.gt(0))
      .exec()
  },
  selectTmotivoBloqueoOt (Vue) {
    const tables = Vue.$offline.db.tables
    const tmotivo = Vue.$offline.tmotivoBloqueoOt
    return Vue.$offline.db
      .select()
      .from(tables.tmotivo_bloqueo_ot)
      .innerJoin(tmotivo.dummyTable, tmotivo.pk.eq(tmotivo.dummyPk))
      .where(tables.tmotivo_bloqueo_ot.estado.gt(0))
      .exec()
  },
  async selectFormData (Vue, id) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .where(tables.parte_trabajo.idparte_trabajo.eq(id))
      .exec()
    )[0]
  },
}
